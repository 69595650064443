/* Main */
h3 {
  font-size: 25px;
  font-weight: normal;
  margin-bottom: 15px;
}
.bg-gray {
  background-color: #f2f2f2;
}
.bg-brown, a.bg-brown:hover {
  background-color: brown;
}
.fw-600 {
  font-weight: 600;
}
#main {
  padding: 20px;
  min-height: 1000px;
}
#main.nav-open {
  margin-left: 400px;
  transition: 0.25s;
}
#left-nav {
  height: 100%;
  background-color: #f2f2f2;
  position: fixed;
  top: 0;
  z-index: 4;
  width: 400px;
  left: -400px;
  transition: 0.25s;
}
#left-nav h4 {
  color: #999;
  font-size: 20px;
  font-weight: normal;
  border-bottom: 2px dotted #ccc;
  padding-bottom: 5px;
}

#left-nav.nav-open {
  box-shadow: 1px 0 10px #888;
  left: 0px;
  transition: 0.25s;
}
#toggler {
}
.nav-open #toggler {
  margin-left: 400px;
  transition: 0.25s;
}
.search-widget input {
  width: 100%;
  height: 38px;
  padding: 5px;
}
.search-widget button {
  width: 100%;
}
.book-selector-widget select {
  height: 38px;
  padding: 5px;
  width: 100%;
}
.toggle-leftnav-icon {
  color: #f5f5f5
}
.toggle-leftnav-icon:hover {
  cursor: pointer;
}
.search-result {
  background-color: #f8f8f8;
}

/*
 * Utilities
 */
.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }
.mb20 {
  margin-bottom: 20px;
}
.p15 {
  padding: 15px;
}
.pr5 {
  padding-right: 5px;
}
.pl5 {
  padding-left: 5px;
}
.mt10 {
  margin-top: 10px;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.clear {
  clear: both;
}
.lightyellow, .highlight {
  background-color: lightyellow;
}
select {
  height: 30px;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color: #982b2b;
  border-color: #591010;
}
.btn-primary.focus, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(107, 40, 44, 0.25)
}
.btn-primary {
  background-color: #ce3c3c;
  border-color: #841212;
}
.btn-primary:hover {
  background-color: #c92016;
  border-color: #841212;
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #e63611b5;
  border-color: #772828;
}
/* Special */
#topnav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background-color: brown;
  padding: 15px 20px;
  z-index: 3;
}
#topnav .title h2 {
  color: white;
  font-weight: normal;
  font-size: 25px;
}
#content {
  margin-top: 75px;
  min-height: 100%;
}
#content p {
  font-size: 18px;
}
#crossReferenceModal {
  position: fixed;
  height: 100%;
  width: 50%;
  top: 65px;
  background-color: lightyellow;
  padding: 20px;
  padding-bottom:100px;
  overflow-y: auto;
}
.translation-widget select {
  position: fixed;
  width: 80px;
  right: 20px;
  top: 16px;
}
.selected, .selected:hover {
  background-color: #fffdf8!important;
}
/* Dialog */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.75);
  z-index: 5;
}
.overlay .dialog {
  margin: auto auto;
  margin-top: 5%;
  width: 50%;
  z-index: 6;
}

/* Responsive */
@media screen and (max-width: 800px) {
  #topnav .title h2 {
    font-size: 17px;
    line-height: 28px;
  }
  #topnav.nav-open .title {
    display: none;
  }
  #topnav.nav-open .translation-widget {
    display: none;
  }
  #left-nav.nav-open {
    width: 82%;
    height: 120%;
  }
  #main.pl-5 {
    padding-left: 2rem!important;
  }
  #main.pr-5 {
    padding-right: 2rem!important;
  }
  #topnav.nav-open #toggler {
    float: right;
  }
  #main.nav-open {
    margin-left: 0;
    transition: none;
  }
}
@media screen and (max-width: 880px) {
  .hide-xs {
    display: none;
  }
  .ntHitBadge, .otHitBadge {
    display: block;
  }
  .ntHitBadge {
    margin-top: 10px;
  }
  .overlay .dialog {
    width: 95%;
  }
}
@media screen and (max-width: 998px) {
  #topnav.nav-open .title h2 {
    font-size: 17px;
    line-height: 28px;
  }
}
@media screen and (max-width: 767px) {
  #crossReferenceModal {
    width: 100%;
    left: 0;
  }
}

@media screen and (max-width: 366px) {
  #topnav .title h2 {
    display: none;
  }
}
